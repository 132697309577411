export const IconMobile = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      strokeMiterlimit="16"
      strokeWidth="1.5"
      d="M5.75 21.25V2.75c0-.414.309-.75.69-.75h10.12c.381 0 .69.336.69.75v18.5c0 .414-.309.75-.69.75H6.44c-.381 0-.69-.336-.69-.75Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9.41 19h4.18"
    />
  </svg>
)

export const IconPhone = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m16.864 14.34-3.327.65c-2.248-1.137-3.636-2.443-4.444-4.478l.622-3.362L8.54 4H5.51c-.911 0-1.628.758-1.492 1.666.34 2.266 1.34 6.374 4.268 9.323 3.075 3.098 7.502 4.442 9.94 4.976.94.207 1.775-.533 1.775-1.503v-2.918l-3.136-1.204Z"
    />
  </svg>
)
export const IconMap = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 13">
    <path
      stroke="#320564"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m4.5 10-2.605.868a.3.3 0 0 1-.395-.284V3.216a.3.3 0 0 1 .205-.284L4.5 2m0 8 3 1m-3-1V2m0 0 3 1m0 8 2.795-.932a.3.3 0 0 0 .205-.284V2.416a.3.3 0 0 0-.395-.284L7.5 3m0 8V3"
    />
  </svg>
)

export const IconCloseMapWindow = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="3.21289"
      y1="12.8646"
      x2="12.6694"
      y2="3.40813"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="3.33019"
      y1="3.4082"
      x2="12.7867"
      y2="12.8647"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const IconClose = () => (
  <svg viewBox="0 0 28 28" fill="none">
    <line
      x1="6.21875"
      y1="21.7811"
      x2="21.7751"
      y2="6.22475"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="6.2189"
      y1="6.22461"
      x2="21.7752"
      y2="21.781"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
export const IconMarker = () => (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M20 10C20 14.4183 12 22 12 22C12 22 4 14.4183 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10Z"
      fill="#46C28C"
      stroke="#46C28C"
      strokeWidth="1.5"
    />
    <path
      d="M12.3756 14.2451L14.2559 10.4951L9.7433 10.4951L11.6236 6.74512"
      stroke="#320564"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const IconMarkerActive = () => (
  <svg viewBox="0 0 40 40" fill="none">
    <path
      d="M33.3327 16.6668C33.3327 24.0307 19.9993 36.6668 19.9993 36.6668C19.9993 36.6668 6.66602 24.0307 6.66602 16.6668C6.66602 9.30303 12.6355 3.3335 19.9993 3.3335C27.3632 3.3335 33.3327 9.30303 33.3327 16.6668Z"
      fill="#320564"
      stroke="#320564"
      strokeWidth="1.5"
    />
    <path
      d="M20.626 23.7417L23.7598 17.4917L16.2388 17.4917L19.3726 11.2417"
      stroke="#46C28C"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const Logo = () => (
  <svg viewBox="0 0 148 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="logo">
      <path
        d="M32 16C32 7.16397 24.8369 0 15.9995 0V7.92665C20.4581 7.92665 24.0731 11.5415 24.0731 16H32ZM16.0005 24.0734C11.5419 24.0734 7.92691 20.4585 7.92691 16H0C0 24.836 7.16313 32 16.0005 32V24.0734Z"
        fill="#320564"
      />
    </g>
    <g id="logo-text">
      <path
        d="M52.5465 18.8986C52.5465 22.2169 50.0142 24.694 46.5862 24.694C43.1691 24.694 40.6487 22.2169 40.6487 18.8986C40.6487 15.5804 43.1691 13.1033 46.5862 13.1033C50.0152 13.1033 52.5465 15.5804 52.5465 18.8986ZM43.6682 18.8986C43.6682 20.6826 44.9171 21.9325 46.5862 21.9325C48.2554 21.9325 49.515 20.6826 49.515 18.8986C49.515 17.1146 48.2554 15.8647 46.5862 15.8647C44.9171 15.8647 43.6682 17.1146 43.6682 18.8986Z"
        fill="#320564"
      />
      <path
        d="M62.7081 13.3422L62.2651 16.2853C61.7768 16.0691 61.0724 15.9328 60.4718 15.9328C59.0976 15.9328 58.1555 16.774 58.1555 18.4099V24.4096H55.1921V13.3984H58.0648V14.6029C58.7573 13.5693 59.8128 13.1033 61.1643 13.1033C61.7995 13.1033 62.2986 13.1941 62.7081 13.3422Z"
        fill="#320564"
      />
      <path
        d="M67.9186 17.3189H68.9633L72.0628 13.3984H75.696L71.3595 18.5796L75.7305 24.4096H72.0855L68.9179 19.955H67.9186V24.4096H64.9563V8.04639H67.9186V17.3189Z"
        fill="#320564"
      />
      <path
        d="M87.9352 19.716C87.9352 23.1932 85.4828 24.6929 82.7355 24.6929C79.9763 24.6929 77.5239 23.1932 77.5239 19.716V13.3984H80.4873V19.4554C80.4873 21.2167 81.452 21.9325 82.7355 21.9325C84.007 21.9325 84.9837 21.2167 84.9837 19.4554V13.3984H87.9352V19.716Z"
        fill="#320564"
      />
      <path
        d="M99.039 16.467C98.165 16.0356 96.7681 15.5804 95.5311 15.5696C94.4076 15.5696 93.8393 15.9675 93.8393 16.5697C93.8393 17.2066 94.6344 17.3655 95.6327 17.5126L96.6093 17.6607C98.9936 18.024 100.322 19.0922 100.322 20.9563C100.322 23.229 98.4599 24.6951 95.2697 24.6951C93.7712 24.6951 91.8072 24.4108 90.3757 23.3998L91.5447 21.1498C92.4867 21.7747 93.634 22.2288 95.2913 22.2288C96.6655 22.2288 97.3234 21.8429 97.3234 21.1952C97.3234 20.6611 96.767 20.3659 95.4955 20.1843L94.5988 20.0588C92.0557 19.7064 90.7949 18.5927 90.7949 16.7179C90.7949 14.457 92.5429 13.1152 95.4382 13.1152C97.1981 13.1152 98.5723 13.445 100.036 14.1608L99.039 16.467Z"
        fill="#320564"
      />
      <path
        d="M113.684 24.4096H110.789V23.1943C110.074 24.0809 109.006 24.694 107.496 24.694C104.464 24.694 102.171 22.205 102.171 18.8986C102.171 15.5922 104.464 13.1033 107.496 13.1033C109.006 13.1033 110.062 13.7174 110.789 14.6148V13.3995H113.684V24.4096ZM105.203 18.8986C105.203 20.5799 106.304 21.9325 108.041 21.9325C109.733 21.9325 110.88 20.6372 110.88 18.8986C110.88 17.16 109.733 15.8647 108.041 15.8647C106.304 15.8647 105.203 17.2162 105.203 18.8986Z"
        fill="#320564"
      />
      <path
        d="M119.986 8.04639H117.022V24.4085H119.986V8.04639Z"
        fill="#320564"
      />
      <path
        d="M134.154 24.4096H131.259V23.1943C130.544 24.0809 129.476 24.694 127.966 24.694C124.935 24.694 122.641 22.205 122.641 18.8986C122.641 15.5922 124.935 13.1033 127.966 13.1033C129.476 13.1033 130.532 13.7174 131.259 14.6148V13.3995H134.154V24.4096ZM125.674 18.8986C125.674 20.5799 126.774 21.9325 128.512 21.9325C130.203 21.9325 131.35 20.6372 131.35 18.8986C131.35 17.16 130.203 15.8647 128.512 15.8647C126.774 15.8647 125.674 17.2162 125.674 18.8986Z"
        fill="#320564"
      />
      <path
        d="M147.892 17.399V24.4096H144.929V18.3537C144.929 16.7632 144.133 15.8874 142.783 15.8874C141.511 15.8874 140.455 16.7167 140.455 18.3764V24.4096H137.491V13.3984H140.375V14.6938C141.158 13.5228 142.362 13.1033 143.611 13.1033C146.109 13.1033 147.892 14.8419 147.892 17.399Z"
        fill="#320564"
      />
    </g>
  </svg>
)

export const MyPagesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
    <path
      stroke="#320564"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M9 1a8 8 0 1 0 0 16A8 8 0 0 0 9 1Z"
    />
    <path
      stroke="#320564"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M2.816 14.077S4.6 11.8 9 11.8s6.183 2.277 6.183 2.277M9 9a2.4 2.4 0 1 0 0-4.8A2.4 2.4 0 0 0 9 9Z"
    />
  </svg>
)

export const GlobeIcon = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4613_15527)">
      <path
        d="M1.33301 8.00016C1.33301 11.682 4.31777 14.6668 7.99967 14.6668C11.6815 14.6668 14.6663 11.682 14.6663 8.00016C14.6663 4.31826 11.6815 1.3335 7.99967 1.3335C4.31777 1.3335 1.33301 4.31826 1.33301 8.00016Z"
        stroke="#320564"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66699 1.36621C8.66699 1.36621 10.667 3.99996 10.667 7.99993C10.667 11.9999 8.66699 14.6337 8.66699 14.6337"
        stroke="#320564"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33301 14.6337C7.33301 14.6337 5.33301 11.9999 5.33301 7.99993C5.33301 3.99996 7.33301 1.36621 7.33301 1.36621"
        stroke="#320564"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75293 10.3335H14.2468"
        stroke="#320564"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75293 5.6665H14.2468"
        stroke="#320564"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4613_15527">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const ArrowLeft = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 8H2M2 8L7.76 2M2 8L7.76 14"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconError = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4844_35708)">
      <path
        d="M0.7821 8.19978C0.710653 8.07605 0.710653 7.92351 0.782106 7.79978L4.21783 1.84893C4.28928 1.72517 4.42133 1.64893 4.56424 1.64893H11.4357C11.5786 1.64893 11.7106 1.72517 11.7821 1.84893L15.2178 7.79978C15.2893 7.92351 15.2893 8.07605 15.2178 8.19978L11.7821 14.1506C11.7106 14.2744 11.5786 14.3506 11.4357 14.3506H4.56424C4.42133 14.3506 4.28928 14.2744 4.21783 14.1506L0.7821 8.19978Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.3335V8.00016"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.6734L8.00667 10.666"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4844_35708">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const IconWarning = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3619 14.0001H2.63805C1.61269 14.0001 0.971066 12.8911 1.48217 12.0022L6.84415 2.67701C7.35681 1.7854 8.64321 1.7854 9.15588 2.677L14.5179 12.0022C15.0289 12.8911 14.3873 14.0001 13.3619 14.0001Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8 6V8.66667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M8 11.3399L8.00667 11.3325"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const IconSuccess = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4844_35809)">
      <path
        d="M4.66699 8.33317L6.66699 10.3332L11.3337 5.6665"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99967 14.6668C11.6815 14.6668 14.6663 11.682 14.6663 8.00016C14.6663 4.31826 11.6815 1.3335 7.99967 1.3335C4.31777 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.682 4.31777 14.6668 7.99967 14.6668Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4844_35809">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const IconInformation = () => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4844_35832)">
      <path
        d="M8 7.6665V10.9998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.00643L8.00667 4.99902"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99967 14.6668C11.6815 14.6668 14.6663 11.682 14.6663 8.00016C14.6663 4.31826 11.6815 1.3335 7.99967 1.3335C4.31777 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.682 4.31777 14.6668 7.99967 14.6668Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4844_35832">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
